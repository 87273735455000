.projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 56px;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
}

.subtitle {
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 0;
}

.projects-container-portfolio-web {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.projects-container-portfolio-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.subtitle-project {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  padding: 0.25rem 0;
}

.projects-container-portfolio-item {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.project-links {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
}

.project-link {
  margin: 0 1rem;
}

.image-project-web {
  height: 200px;
  width: auto;
}

.image-project-mobile {
  height: 400px;
  width: auto;
}

@media (min-width: 0px) and (max-width: 575px) {
  .projects-container-portfolio-web {
    display: grid;
    grid-template-columns: 1fr;
  }

  .projects-container-portfolio-mobile {
    display: grid;
    grid-template-columns: 1fr;
  }

  .image-project-web {
    height: 150px;
    width: auto;
  }

  .image-project-mobile {
    height: 350px;
    width: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .projects-container-portfolio-web {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .image-project-web {
    height: 130px;
    width: auto;
  }

  .image-project-mobile {
    height: 300px;
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .projects-container-portfolio-web {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .image-project-web {
    height: 150px;
    width: auto;
  }

  .image-project-mobile {
    height: 350px;
    width: auto;
  }
}

@media (min-width: 992px) and (max-width: 1190px) {
  .projects-container-portfolio-web {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .projects-container-portfolio-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .image-project-web {
    height: 150px;
    width: auto;
  }
}

@media (min-width: 1191px) and (max-width: 1560px) {
  .projects-container-portfolio-web {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
