@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");

header,
body,
footer {
  font-family: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
}
