@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap);
header,
body,
footer {
  font-family: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.text-hover-nav:hover {
  font-weight: bold;
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.phrases-container {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.content {
  font-size: 1.5rem;
}

.name {
  font-size: 2rem;
}

.icones-container {
  margin-top: 2rem;
}

.icones {
  padding: 0 0.5rem 0 0.5rem;
}

.color {
  background: #1a5276;
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 56px;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
}

.subtitle {
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem 0;
}

.content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image-container {
  display: flex;
  padding: 0 1rem;
  width: 30%;
  justify-content: center;
  align-items: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: 65%;
}

.image {
  height: 600px;
  width: auto;
}

.description-start {
  text-align: start;
}

.description-center {
  text-align: center;
}

.skills-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.skills-column-container {
  display: flex;
  flex-direction: column;
}

.button-resume {
  width: 30%;
  margin: 1rem auto;
}

@media (min-width: 0px) and (max-width: 992px) {
  .content-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image-container {
    width: 100%;
  }

  .text-container {
    width: 100%;
  }

  .button-resume {
    width: 70%;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .image {
    display: flex;
    height: 300px;
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .image {
    display: flex;
    height: 450px;
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .image {
    display: flex;
    height: 450px;
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
  }

  .button-resume {
    width: 35%;
  }
}

.projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 56px;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
}

.subtitle {
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 0;
}

.projects-container-portfolio-web {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.projects-container-portfolio-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.subtitle-project {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  padding: 0.25rem 0;
}

.projects-container-portfolio-item {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.project-links {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
}

.project-link {
  margin: 0 1rem;
}

.image-project-web {
  height: 200px;
  width: auto;
}

.image-project-mobile {
  height: 400px;
  width: auto;
}

@media (min-width: 0px) and (max-width: 575px) {
  .projects-container-portfolio-web {
    display: grid;
    grid-template-columns: 1fr;
  }

  .projects-container-portfolio-mobile {
    display: grid;
    grid-template-columns: 1fr;
  }

  .image-project-web {
    height: 150px;
    width: auto;
  }

  .image-project-mobile {
    height: 350px;
    width: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .projects-container-portfolio-web {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .image-project-web {
    height: 130px;
    width: auto;
  }

  .image-project-mobile {
    height: 300px;
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .projects-container-portfolio-web {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .image-project-web {
    height: 150px;
    width: auto;
  }

  .image-project-mobile {
    height: 350px;
    width: auto;
  }
}

@media (min-width: 992px) and (max-width: 1190px) {
  .projects-container-portfolio-web {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .projects-container-portfolio-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .image-project-web {
    height: 150px;
    width: auto;
  }
}

@media (min-width: 1191px) and (max-width: 1560px) {
  .projects-container-portfolio-web {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.color {
  background: #1a5276;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 56px;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
}

.social {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem 0;
}

.social-links {
  text-decoration: none;
  font-weight: bold;
}

@media (min-width: 0px) and (max-width: 575px) {
  .social {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.color {
  background: #1a5276;
}

.footer-container {
  text-align: center;
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.link-name {
  text-decoration: none;
}

